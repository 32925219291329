import * as React from "react"
import { Helmet } from 'react-helmet'
import Deepdive from "../images/deepdive.svg"

const Resources = () => {
  return (
  <div class="container learn"> 
    {/* <Helmet>
      <title>Deep dive into the Tinyverse | Tinyman</title>
      <meta name="description" content="Read more on Tinyman AMM DEX design, principles and contracts. See links to smart contracts on Algorand, read audit reports, FAQ, Tinyman blogs, and discover Tinyman’s DeFi products on GitHub." />
    </Helmet>
      <div class="learn_bg"></div>
      <div class="sub_intro">
      <div class="page_title">
        <h1>Deep dive into the Tinyverse</h1>
      </div>
        <svg class="page_title_svg" width="1012" height="212" viewBox="0 0 1012 212" fill="none" xmlns="http://www.w3.org/2000/svg">
          <title>Deep dive into the Tinyverse</title>
          <g clip-path="url(#clip0_5549_106583)">
            <path d="M244.075 13.0999C252.4 12.2404 260.726 11.4402 269.021 10.6696C284.961 9.21736 296.338 17.516 297.464 31.3569C298.175 39.8926 298.857 48.458 299.568 56.9937C300.664 70.8642 291.065 80.9115 275.628 82.3341C267.54 83.075 259.452 83.8753 251.363 84.6755C248.934 60.817 246.504 36.9584 244.075 13.0703V13.0999ZM274.414 69.0859C280.576 68.5228 284.546 64.1068 284.043 58.3274C283.302 49.7917 282.532 41.2263 281.791 32.6906C281.287 26.9112 276.547 23.3546 270.236 23.9178C267.184 24.1845 264.162 24.4809 261.111 24.7773C262.563 39.8333 263.985 54.8598 265.436 69.9158C268.429 69.6194 271.392 69.3527 274.384 69.0859H274.414Z" fill="black"/>
            <path d="M304.841 7.61686C320.662 6.37207 336.484 5.3051 352.305 4.35669C352.572 8.62455 352.809 12.8924 353.075 17.1603C342.469 17.7827 331.862 18.494 321.255 19.2646C321.64 24.629 322.025 30.0231 322.41 35.3876C331.743 34.7059 341.046 34.0835 350.379 33.5204C350.616 37.5808 350.883 41.6412 351.12 45.7312C341.846 46.2943 332.573 46.9167 323.299 47.5688C323.714 53.526 324.159 59.5128 324.573 65.4701C334.973 64.7291 345.402 64.0474 355.801 63.4251C356.068 67.6929 356.305 71.9608 356.571 76.2286C341.195 77.1474 325.788 78.2144 310.411 79.3999C308.545 55.4821 306.678 31.5643 304.812 7.6465L304.841 7.61686Z" fill="black"/>
            <path d="M363.919 3.70468C379.741 2.84518 395.592 2.10423 411.443 1.51147C411.591 5.77933 411.768 10.0472 411.917 14.3151C401.31 14.7003 390.673 15.1449 380.067 15.6784C380.333 21.0725 380.6 26.437 380.867 31.8311C390.199 31.3865 399.532 30.9716 408.865 30.6159C409.013 34.6763 409.161 38.7663 409.339 42.8267C400.065 43.1824 390.762 43.5973 381.489 44.0419C381.785 50.0287 382.081 55.986 382.378 61.9728C392.807 61.469 403.236 61.0244 413.635 60.6391C413.783 64.907 413.961 69.1748 414.109 73.4427C398.703 74.0058 383.296 74.7171 367.919 75.547C366.586 51.5995 365.282 27.6521 363.949 3.67504L363.919 3.70468Z" fill="black"/>
            <path d="M421.575 1.15586C430.345 0.859482 439.115 0.622379 447.885 0.444551C464.18 0.0888964 474.965 9.03955 475.113 22.8508C475.261 36.5732 464.773 45.8202 448.922 46.1759C445.485 46.2648 442.078 46.324 438.641 46.4426C438.878 55.1858 439.115 63.929 439.322 72.6425C434.197 72.7907 429.041 72.9389 423.916 73.0871C423.145 49.11 422.346 25.1329 421.575 1.15586V1.15586ZM448.625 33.3426C455.292 33.1944 459.499 28.8969 459.41 23.0583C459.321 17.2492 454.907 13.0703 448.181 13.2185C444.715 13.3074 441.248 13.3963 437.782 13.4852C437.959 20.1834 438.137 26.8816 438.315 33.5797C441.752 33.4908 445.189 33.4019 448.655 33.313L448.625 33.3426Z" fill="black"/>
            <path d="M539.583 0C547.938 0.118552 556.293 0.266741 564.619 0.444569C580.588 0.800224 590.958 10.3436 590.484 24.2438C590.188 32.8092 589.921 41.4042 589.625 49.9695C589.151 63.8697 578.455 72.7611 562.989 72.4054C554.871 72.2276 546.753 72.0794 538.635 71.9609C538.961 47.9838 539.257 23.9771 539.583 0V0ZM563.286 59.098C569.478 59.2462 573.922 55.3044 574.07 49.4953C574.307 40.93 574.544 32.335 574.752 23.7696C574.9 17.9606 570.604 13.9002 564.293 13.752C561.241 13.6927 558.19 13.6334 555.138 13.5742C554.842 28.6895 554.545 43.8048 554.279 58.9202C557.271 58.9795 560.264 59.0387 563.256 59.098H563.286Z" fill="black"/>
            <path d="M598.395 60.1353C603.372 60.3131 608.379 60.5206 613.357 60.728C614.009 45.6127 614.631 30.527 615.283 15.4117C610.216 15.2042 605.12 14.9967 600.054 14.8189C600.202 10.3732 600.38 5.95716 600.528 1.51147C616.023 2.07459 631.519 2.78591 646.984 3.6454C646.747 8.06145 646.51 12.5071 646.244 16.9232C641.177 16.6564 636.081 16.3897 631.015 16.123C630.274 31.2087 629.534 46.324 628.823 61.4097C633.8 61.6468 638.807 61.9135 643.785 62.1803C643.548 66.5963 643.311 71.042 643.044 75.4581C627.993 74.6282 612.942 73.9465 597.861 73.3834C598.009 68.9377 598.187 64.5217 598.335 60.076L598.395 60.1353Z" fill="black"/>
            <path d="M653.888 4.03086C659.339 4.35688 664.791 4.68289 670.242 5.00891C673.472 23.829 676.583 42.6194 679.605 61.4395C684.967 43.2122 690.448 24.9849 695.989 6.75755C701.292 7.14284 706.596 7.55777 711.899 7.9727C703.692 31.3867 695.663 54.8302 687.811 78.3035C681.323 77.8293 674.834 77.3847 668.316 76.9698C663.665 52.637 658.865 28.3043 653.858 4.00122L653.888 4.03086Z" fill="black"/>
            <path d="M718.743 8.56528C734.565 9.86935 750.356 11.3216 766.148 12.9221C765.703 17.1603 765.289 21.4281 764.844 25.6664C754.267 24.5994 743.69 23.5917 733.083 22.6433C732.609 28.0078 732.135 33.3722 731.661 38.7367C740.964 39.5665 750.267 40.426 759.571 41.3745C759.156 45.4348 758.771 49.4952 758.356 53.526C749.112 52.6072 739.838 51.7181 730.565 50.9179C730.032 56.8751 729.498 62.8323 728.995 68.7895C739.394 69.7083 749.793 70.6863 760.193 71.7533C759.748 75.9915 759.334 80.2594 758.889 84.4976C743.542 82.9564 728.165 81.5338 712.788 80.2594C714.773 56.3416 716.729 32.4534 718.714 8.53564L718.743 8.56528Z" fill="black"/>
            <path d="M11.8808 198.011C16.6212 197.033 21.3617 196.055 26.1022 195.106C23.1394 180.287 20.147 165.468 17.1842 150.649C12.3548 151.627 7.52548 152.606 2.69614 153.613C1.8073 149.256 0.888837 144.929 0 140.573C14.7251 137.52 29.4798 134.615 44.2641 131.829C45.0937 136.186 45.8936 140.543 46.7232 144.9C41.8938 145.818 37.0349 146.737 32.2055 147.686C35.0794 162.534 37.983 177.353 40.8569 192.202C45.5973 191.283 50.3674 190.364 55.1079 189.475C55.9375 193.832 56.7374 198.189 57.567 202.545C43.2271 205.242 28.8872 208.088 14.5769 211.051C13.6881 206.695 12.7696 202.368 11.8808 198.011V198.011Z" fill="black"/>
            <path d="M52.9155 130.199C58.2485 129.221 63.6112 128.243 68.9442 127.295C78.6029 140.78 88.1135 154.295 97.5055 167.869C95.0168 153.139 92.528 138.38 90.0393 123.65C94.7501 122.849 99.4609 122.079 104.172 121.308C108.023 144.989 111.905 168.669 115.756 192.35C110.868 193.15 106.009 193.951 101.15 194.78C91.5503 181.236 81.8323 167.751 71.9959 154.325C74.6328 169.055 77.24 183.755 79.8769 198.485C75.255 199.315 70.6034 200.145 65.9814 201.004C61.6261 177.413 57.2708 153.821 52.9155 130.229V130.199Z" fill="black"/>
            <path d="M129.533 130.733C123.341 131.681 117.119 132.659 110.927 133.667C110.216 129.281 109.505 124.894 108.823 120.537C126.333 117.722 143.873 115.084 161.442 112.624C162.065 117.01 162.657 121.426 163.279 125.813C157.057 126.672 150.865 127.562 144.643 128.48C147.488 147.834 150.332 167.158 153.176 186.511C148.258 187.223 143.34 187.964 138.422 188.734C135.459 169.41 132.496 150.086 129.533 130.762V130.733Z" fill="black"/>
            <path d="M174.479 162.564C173.086 152.457 171.723 142.38 170.331 132.274C168.553 119.381 176.79 109.482 191.722 107.586C206.655 105.689 217.113 113.246 218.595 126.169C219.78 136.275 220.965 146.411 222.15 156.518C223.631 169.44 215.484 179.102 201.025 180.939C186.567 182.777 176.256 175.456 174.449 162.564H174.479ZM207.247 158.296C206.003 148.19 204.758 138.083 203.514 127.976C202.803 122.316 199.514 119.53 193.352 120.3C187.189 121.071 184.671 124.598 185.411 130.259C186.745 140.365 188.048 150.472 189.382 160.549C190.122 166.209 193.5 168.966 199.425 168.195C205.351 167.454 207.958 163.957 207.247 158.266V158.296Z" fill="black"/>
            <path d="M299.123 110.638C292.872 111.172 286.65 111.705 280.398 112.268C279.984 107.852 279.598 103.436 279.184 99.0203C296.812 97.4199 314.47 95.9972 332.129 94.7524C332.425 99.1685 332.751 103.614 333.047 108.03C326.796 108.475 320.544 108.919 314.293 109.423C315.804 128.925 317.315 148.427 318.826 167.899C313.878 168.284 308.93 168.669 303.982 169.084C302.353 149.612 300.723 130.11 299.094 110.638H299.123Z" fill="black"/>
            <path d="M338.854 94.3077C343.95 93.9521 349.046 93.6261 354.142 93.3297C354.705 102.517 355.268 111.705 355.831 120.893C361.461 120.537 367.119 120.211 372.778 119.915C372.275 110.727 371.771 101.539 371.297 92.3516C376.393 92.0849 381.489 91.8182 386.585 91.5811C387.74 115.558 388.866 139.506 390.022 163.483C385.074 163.72 380.126 163.986 375.178 164.253C374.615 153.91 374.052 143.566 373.489 133.252C367.86 133.548 362.261 133.874 356.661 134.23C357.283 144.574 357.935 154.888 358.557 165.231C353.609 165.528 348.661 165.854 343.713 166.18C342.114 142.232 340.514 118.314 338.884 94.367L338.854 94.3077Z" fill="black"/>
            <path d="M397.34 91.0476C412.628 90.3659 427.945 89.8621 443.233 89.4768C443.352 93.7447 443.441 98.0125 443.559 102.28C433.308 102.547 423.057 102.873 412.805 103.258C413.013 108.653 413.22 114.017 413.398 119.411C422.405 119.085 431.412 118.789 440.419 118.552C440.537 122.612 440.626 126.702 440.745 130.762C431.767 131 422.82 131.296 413.842 131.622C414.079 137.609 414.287 143.566 414.524 149.553C424.568 149.168 434.641 148.871 444.715 148.604C444.833 152.872 444.922 157.14 445.041 161.408C430.167 161.793 415.324 162.297 400.451 162.949C399.414 138.972 398.377 115.025 397.31 91.0476H397.34Z" fill="black"/>
            <path d="M526.28 102.073C520.029 102.014 513.748 101.984 507.496 101.984C507.496 97.5385 507.496 93.1224 507.496 88.6768C525.184 88.6768 542.872 88.8842 560.56 89.2695C560.471 93.7152 560.352 98.1313 560.263 102.577C554.012 102.429 547.76 102.34 541.479 102.251C541.213 121.812 540.916 141.343 540.65 160.904C535.702 160.845 530.724 160.786 525.777 160.727C525.925 141.166 526.102 121.634 526.251 102.073H526.28Z" fill="black"/>
            <path d="M567.256 148.13C572.085 148.249 576.885 148.397 581.714 148.545C582.188 133.43 582.662 118.315 583.136 103.229C578.218 103.081 573.329 102.933 568.411 102.814C568.53 98.3683 568.648 93.9523 568.737 89.5066C583.699 89.8919 598.661 90.3957 613.623 91.0478C613.446 95.4934 613.238 99.9095 613.06 104.355C608.172 104.148 603.254 103.94 598.365 103.762C597.802 118.878 597.239 133.963 596.676 149.079C601.476 149.257 606.305 149.464 611.105 149.672C610.927 154.117 610.72 158.533 610.542 162.979C596.024 162.357 581.477 161.853 566.959 161.497C567.078 157.051 567.196 152.635 567.285 148.19L567.256 148.13Z" fill="black"/>
            <path d="M622.393 91.4033C627.785 91.67 633.207 91.9368 638.599 92.2035C644.91 107.526 651.073 122.849 657.147 138.202C658.065 123.264 658.983 108.356 659.902 93.4187C664.642 93.715 669.413 94.0114 674.153 94.3374C672.523 118.285 670.924 142.203 669.294 166.15C664.376 165.824 659.458 165.498 654.539 165.202C648.288 149.849 641.918 134.497 635.4 119.174C634.629 134.112 633.829 149.049 633.059 163.957C628.378 163.72 623.697 163.483 618.986 163.246C620.112 139.269 621.238 115.321 622.334 91.344L622.393 91.4033Z" fill="black"/>
            <path d="M694.122 142.262C688.315 126.317 682.36 110.401 676.286 94.5154C681.619 94.871 686.952 95.2563 692.285 95.6713C695.989 105.837 699.663 116.003 703.248 126.198C708.255 116.714 713.321 107.23 718.447 97.7756C723.75 98.2201 729.024 98.6943 734.327 99.1982C725.794 113.928 717.38 128.688 709.114 143.507C708.403 152.013 707.692 160.549 706.981 169.055C702.033 168.64 697.085 168.255 692.137 167.869C692.789 159.363 693.441 150.827 694.093 142.321L694.122 142.262Z" fill="black"/>
            <path d="M734.298 99.1685C739.542 99.6427 744.816 100.147 750.06 100.68C752.489 119.589 754.8 138.468 757.052 157.377C762.918 139.328 768.844 121.278 774.888 103.258C780.014 103.822 785.11 104.385 790.235 104.977C781.436 128.125 772.814 151.302 764.37 174.508C758.119 173.826 751.867 173.204 745.616 172.582C742.031 148.13 738.268 123.649 734.328 99.1981L734.298 99.1685Z" fill="black"/>
            <path d="M796.813 105.689C812.041 107.467 827.27 109.394 842.469 111.468C841.906 115.707 841.314 119.945 840.751 124.153C830.559 122.76 820.367 121.456 810.175 120.182C809.523 125.517 808.842 130.881 808.19 136.216C817.137 137.313 826.115 138.468 835.062 139.684C834.529 143.714 833.966 147.745 833.433 151.806C824.515 150.62 815.626 149.464 806.708 148.368C805.968 154.295 805.257 160.252 804.516 166.18C814.53 167.425 824.515 168.699 834.529 170.062C833.966 174.301 833.374 178.539 832.811 182.747C818.056 180.732 803.272 178.865 788.487 177.116C791.272 153.287 794.087 129.459 796.872 105.63L796.813 105.689Z" fill="black"/>
            <path d="M852.068 112.802C860.009 113.928 867.949 115.054 875.86 116.24C891.562 118.611 900.332 128.629 898.169 141.847C896.688 151.094 890.437 158.089 882.2 160.074C885.252 170.537 888.274 180.969 891.207 191.431C885.874 190.572 880.541 189.742 875.208 188.912C872.719 179.339 870.201 169.796 867.623 160.252C865.312 159.897 862.971 159.571 860.661 159.215C859.357 168.195 858.024 177.176 856.72 186.126C851.831 185.415 846.943 184.704 842.054 184.022C845.373 160.252 848.72 136.512 852.039 112.743L852.068 112.802ZM871.119 147.834C877.697 148.812 882.141 146.026 883.03 140.187C883.978 134.141 880.6 129.933 873.963 128.925C871.09 128.48 868.186 128.065 865.312 127.651C864.394 133.963 863.475 140.276 862.557 146.589C865.431 147.004 868.275 147.419 871.149 147.864L871.119 147.834Z" fill="black"/>
            <path d="M898.525 172.7C903.473 173.5 908.421 174.33 913.368 175.16C912.569 179.932 916.094 183.607 922.494 184.733C928.686 185.8 932.834 183.666 933.693 178.805C934.434 174.627 932.212 171.04 927.649 169.114C925.309 168.136 922.938 167.158 920.598 166.18C908.776 161.26 902.91 152.042 904.747 140.839C906.761 128.391 917.487 121.812 932.449 124.42C947.5 127.028 955.559 136.571 953.307 148.664C948.3 147.745 943.293 146.856 938.286 145.967C939.086 141.491 936.152 138.083 930.049 137.016C924.331 136.038 920.657 138.113 919.798 143.151C919.146 146.945 921.22 150.501 926.049 152.428C928.39 153.406 930.701 154.384 933.041 155.362C945.396 160.549 950.759 169.381 948.626 180.821C946.315 193.328 934.967 199.937 920.005 197.329C904.925 194.721 896.51 185.178 898.525 172.73V172.7Z" fill="black"/>
            <path d="M966.729 131.622C981.839 134.467 996.92 137.431 1012 140.573C1011.14 144.752 1010.28 148.93 1009.39 153.109C999.29 151.005 989.187 148.99 979.084 147.034C978.047 152.339 977.039 157.614 976.002 162.92C984.891 164.639 993.779 166.417 1002.64 168.254C1001.81 172.255 1001.01 176.227 1000.18 180.228C991.349 178.42 982.52 176.642 973.662 174.923C972.536 180.791 971.38 186.66 970.254 192.528C980.18 194.454 990.105 196.44 1000 198.485C999.142 202.664 998.282 206.843 997.394 211.022C982.757 207.999 968.092 205.094 953.426 202.338C957.84 178.746 962.285 155.184 966.699 131.592L966.729 131.622Z" fill="black"/>
          </g>
          <defs>
            <clipPath id="clip0_5549_106583">
              <rect width="1012" height="212" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <img class="falling" src={Deepdive} alt="Tinyman Resources"/>
        <div class="intro_description">
          <p>Learn about the core concepts and the main points of Tinyman and DeFi products of Algorand ecosystem. Swapping, pooling, fees, and more...</p>
        </div>
        <div class="section quick_links"> 
          <ul>
            <li>
              <a href="https://tinymanorg.medium.com/who-is-this-tinyman-6eb2e787bf74" target="_blank" rel="noreferrer">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10 2H6C3.79086 2 2 3.79086 2 6V10C2 12.2091 3.79086 14 6 14H10C12.2091 14 14 12.2091 14 10V6C14 3.79086 12.2091 2 10 2ZM6 0C2.68629 0 0 2.68629 0 6V10C0 13.3137 2.68629 16 6 16H10C13.3137 16 16 13.3137 16 10V6C16 2.68629 13.3137 0 10 0H6ZM7 8H9V12H7V8ZM9 4H7V6H9V4Z" fill="black"/>
                </svg>
                What is Tinyman?
              </a>
            </li>
            <li>
              <a href="https://docs.tinyman.org/" target="_blank" rel="noreferrer">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 0C2.84315 0 1.5 1.34315 1.5 3V13C1.5 14.6569 2.84315 16 4.5 16H13.5H14.5V14H14V12H14.5V10V2V1V0H4.5ZM12.5 2H4.5C3.94772 2 3.5 2.44772 3.5 3V10.1707C3.81278 10.0602 4.14936 10 4.5 10H12.5V2ZM4.5 12H12V14H4.5C3.94772 14 3.5 13.5523 3.5 13C3.5 12.4477 3.94772 12 4.5 12Z" fill="black"/>
                </svg>
                Documentation
              </a>
            </li>
            <li>
              <a href="https://docs.tinyman.org/faq" target="_blank" rel="noreferrer">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.5C0 1.567 1.567 0 3.5 0H12.5C14.433 0 16 1.567 16 3.5C16 5.433 14.433 7 12.5 7H3.5C1.567 7 0 5.433 0 3.5ZM3.5 2H12.5C13.3284 2 14 2.67157 14 3.5C14 4.32843 13.3284 5 12.5 5H3.5C2.67157 5 2 4.32843 2 3.5C2 2.67157 2.67157 2 3.5 2Z" fill="black"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.5C0 10.567 1.567 9 3.5 9H12.5C14.433 9 16 10.567 16 12.5C16 14.433 14.433 16 12.5 16H3.5C1.567 16 0 14.433 0 12.5ZM3.5 11H12.5C13.3284 11 14 11.6716 14 12.5C14 13.3284 13.3284 14 12.5 14H3.5C2.67157 14 2 13.3284 2 12.5C2 11.6716 2.67157 11 3.5 11Z" fill="black"/>
                </svg>
                Frequently Asked Questions
              </a>
            </li>
            <li>
              <a href="https://docs.tinyman.org/protocol-specification" target="_blank" rel="noreferrer">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16 3C16 1.34315 14.6569 0 13 0H3C1.34962 0 0.0104981 1.33267 6.1423e-05 2.9806L0 3V13C0 14.6569 1.34315 16 3 16H13C14.6569 16 16 14.6569 16 13V3ZM3 2C2.51675 2 2.11356 2.34278 2.02032 2.79847C2.007 2.86356 2 2.93096 2 3V5H14V3C14 2.44772 13.5523 2 13 2H3ZM9 14H12C13.1046 14 14 13.1046 14 12V7H9V14ZM7 7V14H3C2.44772 14 2 13.5523 2 13V7H7Z" fill="black"/>
                </svg>
                Protocol Specification
              </a>
            </li>
            <li>
              <a href="https://docs.tinyman.org/fees" target="_blank" rel="noreferrer">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 11V9H10V11H12Z" fill="black"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15 4V5.35418C15.6224 6.05931 16 6.98555 16 8V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4C0 1.79086 1.79086 0 4 0H11C13.2091 0 15 1.79086 15 4ZM4 2H11C12.1046 2 13 2.89543 13 4V4.12602C12.6804 4.04375 12.3453 4 12 4H4C3.27143 4 2.58835 4.19479 2 4.53513V4C2 2.89543 2.89543 2 4 2ZM12 6H4C2.89543 6 2 6.89543 2 8V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V8C14 6.89543 13.1046 6 12 6Z" fill="black"/>
                </svg>
                Fees
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="section helper">
        <a class="helper_box" href="https://www.youtube.com/playlist?list=PLFRZV55GOPLtWhefSq2lOS12R4mDLzPFZ" target="_blank" rel="noreferrer">
          <h5>Tinyman Tutorials</h5>
          <p>Tinyman provides basic tutorials for beginner swappers and liquidity providers.</p>
        </a>
        <a class="helper_box" href="https://tinymanorg.medium.com/" target="_blank" rel="noreferrer">
          <h5>Tinyman Blog</h5>
          <p>Follow all the developments and updates in Tinyman with all the details.</p>
        </a>        
        <a class="helper_box" href="https://discord.gg/wvHnAdmEv6" target="_blank" rel="noreferrer">
          <h5>Join our Discord</h5>
          <p>Still have any questions? Or, are you interested in contributing to Tinyman? We are eager to hear your ideas!</p>
        </a>
      </div>

      <div class="section">
        <h2>Developers</h2>
        <div class="developers">
          <ul>
            <li>
              <h4>Contracts</h4>
              <p>The contract and protocol design for Tinyman v2 is significantly different from v1 as it uses more recent features of Algorand Virtual Machine, thanks to Tealish.</p>
            </li>
            <li>
              <a href="https://github.com/tinymanorg/tinyman-amm-contracts-v2" target="_blank" rel="noreferrer">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.1394 3.45001L1.1001 13.4893L2.51431 14.9036L12.5539 4.86401L12.5539 12.7637H14.5539L14.5539 1.45001L3.24015 1.45001L3.24015 3.45001L11.1394 3.45001Z" fill="black"/>
                </svg>
                Tinyman Contracts v2
              </a>
            </li>
            <li>
              <a href="https://docs.tinyman.org/audits-and-security" target="_blank" rel="noreferrer">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.1394 3.45001L1.1001 13.4893L2.51431 14.9036L12.5539 4.86401L12.5539 12.7637H14.5539L14.5539 1.45001L3.24015 1.45001L3.24015 3.45001L11.1394 3.45001Z" fill="black"/>
                </svg>
                Audits & Security
              </a>
            </li>
            <li>
              <a href="https://immunefi.com/bounty/tinymanv2/" target="_blank" rel="noreferrer">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.1394 3.45001L1.1001 13.4893L2.51431 14.9036L12.5539 4.86401L12.5539 12.7637H14.5539L14.5539 1.45001L3.24015 1.45001L3.24015 3.45001L11.1394 3.45001Z" fill="black"/>
                </svg>
                Bug Bounty Program
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <h4>Official SDKs</h4>
              <p>These SDKs have been developed and are maintained by the Tinyman core development team.</p>
            </li>
            <li>
              <a href="https://github.com/tinymanorg/tinyman-py-sdk" target="_blank" rel="noreferrer">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.1394 3.45001L1.1001 13.4893L2.51431 14.9036L12.5539 4.86401L12.5539 12.7637H14.5539L14.5539 1.45001L3.24015 1.45001L3.24015 3.45001L11.1394 3.45001Z" fill="black"/>
                </svg>
                Tinyman Python SDK
              </a>
            </li>
            <li>
              <a href="https://github.com/tinymanorg/tinyman-js-sdk" target="_blank" rel="noreferrer">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.1394 3.45001L1.1001 13.4893L2.51431 14.9036L12.5539 4.86401L12.5539 12.7637H14.5539L14.5539 1.45001L3.24015 1.45001L3.24015 3.45001L11.1394 3.45001Z" fill="black"/>
                </svg>
                Tinyman Javascript SDK
              </a>
            </li>
            <li></li>
          </ul>
        </div>
      </div>
      <div class="section products">
        <div class="product_box asa-list">
          <div class="cover"></div>
          <div class="content">
            <p>All project icons built on Algorand are collected in one place where everyone can easily access and use.</p>
            <a href="https://asa-list.tinyman.org/" class="link-s" target="_blank" rel="noreferrer">
              <div data-hover-animation="Go to asa-list website app">
                Go to asa-list website app
              </div>
            </a>
          </div>
        </div>
        <div class="product_box tealish">
          <div class="cover"></div>
          <div class="content">
            <p>Tealish is a readable language for the Algorand Virtual Machine, built by Tinyman team.</p>
            <a href="https://github.com/tinymanorg/tealish" class="link-s" target="_blank" rel="noreferrer">
              <div data-hover-animation="Go to Tealish repository">
              Go to Tealish repository
              </div>
            </a>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Resources
